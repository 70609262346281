<template>
<div class="page">
  <!-- <el-breadcrumb class="noBoard" separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">{{$t('breadCrumb.mainPage')}}</el-breadcrumb-item>
    <el-breadcrumb-item>{{$t('breadCrumb.reportMain')}}</el-breadcrumb-item>
    <el-breadcrumb-item>{{$t('breadCrumb.reportOdometer')}}</el-breadcrumb-item>
  </el-breadcrumb> -->
  <el-card class="tableArea">
    <el-form :inline="true" ref="queryInfoRef" :model="queryInfo" class="demo-form-inline">
      <el-row>
        <el-col :span="8">
          <el-form-item :label="$t('alterReport.query.deviceImei')" >
            <el-input v-model="queryInfo.deviceImei" :placeholder="$t('alterReport.query.deviceImeiPlaceholder')"></el-input>
          </el-form-item>
          <el-button type="primary" size="normal" @click="selectDeviceImei">{{$t('common.select')}}</el-button>
        </el-col>
        <el-col :span="8">
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="To"
            :format="this.$i18n.locale.includes('en')?'dd/MM/yyyy':'yyyy-MM-dd'"
            :start-placeholder="$t('alterReport.query.startTimePlaceholder')"
            :end-placeholder="$t('alterReport.query.endTimePlaceholder')">
          </el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-form-item>
            <el-button type="primary" size="normal" @click="getReport">{{$t('common.search')}}</el-button>
            <el-button type="warning" size="normal" @click="resetQuery">{{$t('common.reset')}}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table :data="reportList" border stripe>
      <el-table-column type="index" label="#"></el-table-column>
      <el-table-column prop="reportDate" :label="$t('odometerReport.reportDate')" width="180px;"></el-table-column>
      <el-table-column prop="deviceImei" :label="$t('odometerReport.deviceImei')" width="130px;"></el-table-column>
      <el-table-column prop="deviceName" :label="$t('odometerReport.deviceName')" width="130px;"></el-table-column>
      <el-table-column prop="tripStartDate" :label="$t('odometerReport.tripStartDate')" width="180px;"></el-table-column>
      <el-table-column prop="tripEndDate" :label="$t('odometerReport.tripEndDate')" width="180px;"></el-table-column>
      <el-table-column prop="odometer" :label="$t('odometerReport.odometer')" width="120px;"></el-table-column>
      <el-table-column prop="oilConsumption" :label="$t('odometerReport.oilConsumption')" width="140px;"></el-table-column>
      <el-table-column prop="avgSpeed" :label="$t('odometerReport.avgSpeed')" width="120px;"></el-table-column>
      <el-table-column prop="maxSpeed" :label="$t('odometerReport.maxSpeed')" width="120px;"></el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.pageNum"
      :page-sizes="[20, 50, 100]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </el-card>
  <el-dialog :title="$t('devicePage.addDiagTitle')" :visible.sync="selectDiag" width="55%" top="10px;"  @close="selectDiagClosed">
    <el-table :data="deviceList" @current-change="handleClick">
      <el-table-column type="index" :label="$t('common.sn')"></el-table-column>
      <el-table-column type="deviceId" label="deviceId" v-if="false"></el-table-column>
      <el-table-column prop="deviceImei" :label="$t('device.deviceImei')" width="120px;"></el-table-column>
      <el-table-column prop="deviceName" :label="$t('device.deviceName')" width="105px;"></el-table-column>
      <el-table-column prop="timeZone" :label="$t('device.timeZone')" width="105px;"></el-table-column>
      <el-table-column prop="baseMileage" :label="$t('device.baseMileage')" width="105px;"></el-table-column>
      <el-table-column prop="expireDate" :label="$t('device.expireDate')" width="110px;"></el-table-column>
      <el-table-column prop="deviceRemark" :label="$t('device.deviceRemark')"></el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleDeviceSizeChange"
      @current-change="handleDeviceCurrentChange"
      :current-page="queryDevice.pageNum"
      :page-sizes="[10, 20, 50]"
      :page-size="queryDevice.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="deviceTotal"
    ></el-pagination>
    <el-row>
      <el-col :span="6" :offset="18">
        <el-button type="primary" size="normal" @click="handleSelected">{{$t('common.select')}}</el-button>
        <el-button type="warning" size="normal" @click="selectDiag = false">{{$t('common.close')}}</el-button>
      </el-col>
    </el-row>
  </el-dialog>
</div>
</template>

<script>
import { getOdometerReport } from '@/api/gpsReport'
import { checkLogin } from '@/api/globalProcessErr'
import { getTodayZero, getDateShortStr } from '@/api/dateFunc'
import { listGpsDevice } from '@/api/gpsDevice'

export default {
  name: 'odometerReport',
  data () {
    return {
      queryInfo: {
        deviceImei: '',
        startDate: undefined,
        endDate: undefined,
        pageNum: 1,
        pageSize: 20
      },
      total: 0,
      reportList: [],
      dateRange: [],
      selectDiag: false,
      deviceList: [],
      deviceTotal: 0,
      queryDevice: {
        pageNum: 1,
        pageSize: 10
      },
      selectedDeviceImei: undefined
    }
  },
  created () {
    this.dateRange[0] = getTodayZero()
    this.dateRange[0].setTime(this.dateRange[0].getTime() - 3600 * 1000 * 24)
    this.dateRange[1] = getTodayZero()
  },
  mounted () {
    // this.getReport()
  },
  methods: {
    getReport () {
      const _this = this
      _this.queryInfo.startDate = getDateShortStr(_this.dateRange[0])
      _this.queryInfo.endDate = getDateShortStr(_this.dateRange[1])
      getOdometerReport(_this.queryInfo).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return _this.$message.error(res.data.msg)
        }
        _this.reportList = res.data.data.list
        _this.total = res.data.data.total
      }).catch(err => {
        return _this.$message.error(err.message)
      })
    },
    resetQuery () {
      this.queryInfo.deviceImei = ''
      this.dateRange[0] = getTodayZero()
      this.dateRange[0].setTime(this.dateRange[0].getTime() - 3600 * 1000 * 24)
      this.dateRange[1] = getTodayZero()
      this.pageNum = 1
      this.pageSize = 20
      this.getReport()
    },
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize
      this.getReport()
    },
    handleCurrentChange (newNum) {
      // console.log(newSize)
      this.queryInfo.pageNum = newNum
      this.getReport()
    },
    getDeviceList () {
      listGpsDevice(this.queryDevice).then(res => {
        checkLogin(res, this.$message)
        this.deviceList = res.data.data.list
        this.deviceTotal = res.data.data.total
      }).catch(err => {
        return this.$message.error(err.message)
      })
    },
    selectDeviceImei () {
      this.getDeviceList()
      this.selectDiag = true
    },
    selectDiagClosed () {
      this.selectDiag = false
    },
    handleDeviceSizeChange (newSize) {
      this.queryDevice.pageSize = newSize
      this.getDeviceList()
    },
    handleDeviceCurrentChange (newNum) {
      // console.log(newSize)
      this.queryDevice.pageNum = newNum
      this.getDeviceList()
    },
    handleSelected () {
      this.queryInfo.deviceImei = this.selectedDeviceImei
      this.selectDiag = false
    },
    handleClick (val, oldRow) {
      this.selectedDeviceImei = val.deviceImei
    }
  }
}
</script>

<style scoped>

</style>
